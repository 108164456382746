.dashboard {
    font-family: 'Source Sans Pro';
    font-size: 16px;
}
.dashboard__gridItem {
    @apply p-2 truncate w-full font-bold
}
.dashboard__gridItem--fullText {
    text-overflow: unset;
    overflow: unset;
}
.dashboard__gridItem::before {
    content: attr(data-label);
    @apply font-light mr-2
}
.dashboard__gridItem--beforeHide::before {
    @apply hidden
}

.sandbox-bg{
    background-image: radial-gradient(#cfcfcf 1px,transparent 0);
    background-size: 15px 15px;
}

.shadow-border{
    box-shadow: rgb(242 242 242) 0px -1px 0px inset
}

.diagonal-stripes-bg{
    /*background: repeating-linear-gradient(45deg, #cfcfcf, #cfcfcf 1px, #fff 1px, #fff 2px );*/
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #f0f0f0 10px, #f0f0f0 15px);
}

@media(min-width: 850px) {
    .dashboard__gridItem {
        @apply font-normal 
    }
    .dashboard__gridItem::before {
        @apply hidden
    }
}