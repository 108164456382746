@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');


body {
  margin: 0;
  font-family: 'Cabin', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  white-space: pre-line;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Tailwind scrollbar */
html,
body,
.memoryScrollbar, .memoryScrollbarPlaylist {
  @apply scrollbar-thin scrollbar-track-gray-100 scrollbar-track-rounded scrollbar-thumb-gray-300 scrollbar-thumb-rounded;
}

.memoryScrollbar::-webkit-scrollbar {
  width: 8px;
}
.memoryScrollbar::-webkit-scrollbar-track {
  background-color: rgb(243, 244, 246) !important;
}
.memoryScrollbarPlaylist::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.memoryScrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(209, 213, 219) !important;
}

/* toggle switch */
.toggle-bg:after {
  content: '';
  @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-5 w-5 transition shadow-sm;
}
input:checked + .toggle-bg:after {
  transform: translateX(100%);
  @apply border-white;
}
input:checked + .toggle-bg {
  @apply bg-blue-600 border-blue-600;
}