/* Skeleton */
.skeleton {
  @apply w-full overflow-hidden;
  background: #e2e2e2;
}
.skeleton::after {
  @apply block absolute top-0 z-10 w-full h-full;
  content: "";
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  transform: translateX(-100%);
  animation: loadingSkeleton 1.5s infinite;
}

@keyframes loadingSkeleton {
  100% {
    transform: translateX(100%);
  }
}
